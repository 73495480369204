/* eslint-disable no-console */
// See: https://www.gatsbyjs.org/docs/browser-apis/

import { navigate } from 'gatsby'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import scrollToElement from '@helpers/scroll-to-element'

const hashbangRegex = /#!$/

export const onRouteUpdate = ({ prevLocation }) => {
  // Only want to force a page view for analytics after the initial page view.
  // By checking for the existence of prevLocation, we can skip this function
  // call when there is no prevLocation, which is on initial app load, and every
  // route update after that will have a prevLocation, so we call the analytics
  // page view.
  if (prevLocation) {
    // This function spawned out of this ticket https://app.clickup.com/t/2308664/ACC-3088
    // where we need to force a page view to fire in order to track analytics correctly
    const analyticsChangeContext = get(window, '__analyticsChangeContext', [])
    if (isFunction(analyticsChangeContext.push)) {
      analyticsChangeContext.push({
        eventData: {
          eventName: 'page_view',
        },
      })
    }
  }

  return true
}

export const onPreRouteUpdate = ({ location }) => {
  if (location.hash) {
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual'
    }
  }
}

export const onClientEntry = () => {
  if (window?.location && hashbangRegex.test(window.location.href)) {
    navigate(window.location.pathname)
  }
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const onInitialClientRender = () => {
  if (window?.location?.hash) {
    window.setTimeout(() => {
      scrollToElement()
    }, 1000)
  }
}
