export default function scrollToElement(
  suppliedHash = undefined,
  trigger = true
) {
  const scrollToHashElement = () => {
    const hashCleanup = /^#|^#!/
    const { hash } = window.location
    const elementToScroll = document.getElementById(
      (suppliedHash || hash)?.replace('#', '').replace(hashCleanup, '')
    )

    if (!elementToScroll) return

    elementToScroll.scrollIntoView()
  }

  if (!trigger) return

  scrollToHashElement()
}
