// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-templates-integration-category-entry-js": () => import("./../../../src/templates/integration-category-entry.js" /* webpackChunkName: "component---src-templates-integration-category-entry-js" */),
  "component---src-templates-integration-details-entry-js": () => import("./../../../src/templates/integration-details-entry.js" /* webpackChunkName: "component---src-templates-integration-details-entry-js" */),
  "component---src-templates-storyblok-entry-js": () => import("./../../../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */)
}

